.wrapper {
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.produkt {
  flex-grow: 1;
  &Razem {
    @extend .produkt;

    font-size: 1.8rem;
    line-height: 1.1em;
    font-weight: 700;
    text-transform: uppercase;
  }
}
.ilosc {
  text-align: right;
  &Razem {
    @extend .ilosc;

    text-align: right;
    font-weight: 700;
  }
}
.cena {
  text-align: right;
  &Razem {
    @extend .cena;

    text-align: right;
    font-weight: 700;
  }
}
