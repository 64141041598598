.ikony {
  display: flex;
  justify-content: flex-end;
  width: 60%;
  align-items: center;

  @media screen and (max-width: 700px) {
    margin-left: 2rem;
    width: 80%;
  }
  img {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }
  .napoje {
    width: 1.8rem;
  }

  .divik {
    display: flex;

    .divikmini {
      display: flex;
    }
    .numerek {
      align-self: center;
    }
    img {
      align-self: flex-end;
    }
  }
}
