.list-group {
  .list-group-item-dark {
    // margin-bottom: 2rem;
  }
}

.list-group-item {
  background-color: inherit;
  margin-bottom: 2rem;
  border-radius: 5px !important;
}

.buttonDriver {
  text-transform: uppercase;
  outline: none;
  width: 100%;
  height: 5rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid black;
  transition: 0.3s ease-out;
  &:hover {
    opacity: 0.8;
    transition: 0.3s;
    border: 2px solid rgba(0, 0, 0, 0.651);
  }
  &.active {
    background-color: black;
    transition: 0.3s;
    color: rgb(245, 232, 223);
  }
}

.wyjazdy {
  margin-top: 2rem;
}
