.row {
  display: flex;
  &Razem {
    @extend .row;

    padding-top: 2rem;
    margin-top: 4rem;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background: #0b0b0b;
      height: 4px;
      border-radius: 2px;
      width: calc(100% - 16px);
      position: absolute;
      left: 8px;
      top: 0;
    }
  }
}

.produkt {
  flex-grow: 1;
  &Razem {
    @extend .produkt;

    font-size: 1.8rem;
    line-height: 1.1em;
    font-weight: 700;
    text-transform: uppercase;
  }
}
.ilosc {
  text-align: right;
  &Razem {
    @extend .ilosc;

    text-align: right;
    font-weight: 700;
  }
}
.cena {
  text-align: right;
  &Razem {
    @extend .cena;

    text-align: right;
    font-weight: 700;
  }
}
