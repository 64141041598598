.row {
  &:hover {
    cursor: pointer;
  }
}

.table {
  overflow: auto;
  display: block;
  table-layout: auto;
}
