.cell {
  //grid-area: main;
  display: flex;
  align-items: flex-start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  flex-direction: column;
  justify-content: center;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -1200px;
    left: -1200px;
    z-index: -1;
    background: transparent;
    transition: all 0.3s ease;
    border: 1px solid transparent;
  }
  &:hover {
    &:before {
      background: rgba(0, 0, 0, 0.1);
      border-color: #0b0b0b;
    }
  }
  &Head {
    @extend .cell;

    //grid-area: header;
    &Center {
      @extend .cellCenter;
      align-items: center;
    }
  }
  &Center {
    @extend .cell;
    align-items: center;
    justify-content: center;
  }
}

.cellCenterNie {
  @extend .cellCenter;

  color: red;
}

.zrealizowano {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: block;
  background: url("../../../assets/svg/akcept.svg") no-repeat center center;
  background-size: 100%;
  &.Nie {
    @extend .zrealizowano;

    background: url("../../../assets/svg/reject.svg") no-repeat center center;
    background-size: 100%;
  }
}

.koszyk {
  &:hover {
    opacity: 0.6;
  }
}
