.wyjazdyBigCont {
  width: 100%;
  display: flex;
  .wyjazdyRight {
    width: 70%;
    margin-left: 30%;
    .wyjazdCont {
      width: 100%;
      border: 2px solid black;
      border-radius: 10px;
      text-align: center;
      margin-bottom: 5rem;
      padding: 1rem;

      background-color: rgb(245, 232, 223);
    }
    .rowStyle {
      overflow: 'hidden';
      clear: 'both';
      width: 100%;
      justify-content: space-between;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      margin-bottom: 5rem;

      .dustbinCont {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
        margin-right: 2rem;
        min-width: 31%;
        width: 31%;
        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 5rem;
          border: 2px solid black;
          border-radius: 10px;
          margin-bottom: 5rem;
          background-color: rgb(245, 232, 223);
        }

        // div {
        //   width: 30%;
        // }
      }
    }
  }
  .wyjazdyLeft {
    overflow: hidden;
    // max-height: 90vh;
    height: 90vh;
    width: 25%;
    // top: 99px;
    // right: -20px;

    position: fixed;
    @media screen and (max-width: 1800px) {
      overflow: hidden;
      // right: 0;
      // top: 99px;
      height: 90vh;
      width: 28%;
      position: fixed;
      z-index: 0;
      margin-left: -45px;
    }
    .firmyCont {
      max-height: 90vh;

      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-around;
      align-items: center;
      overflow-y: scroll;
      margin-right: -50px; /* maximum width of scrollbar */
      padding-right: 50px;
      // height: 100%;
      // margin-right: -50px; /* maximum width of scrollbar */
      // padding-right: 50px; /* maximum width of scrollbar */
      // overflow-y: scroll;
    }
    .firmaBrick {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: 0rem 2rem 4rem 2rem;
      padding: 2rem;
      border: 2px solid black;
      width: 80%;
      border-radius: 10px;
      height: 13rem;
      @media screen and (max-width: 1800px) {
        // width: 100%;
      }
    }
  }

  .boxy {
    width: 40%;
  }
}
.ikonki {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  div {
    display: flex;
    height: 4rem;
    justify-content: center;
    align-items: center;
    margin-right: 1.5rem;
  }
  .iko {
    height: 2.6rem;
    width: 2.6rem;
  }
  .ikoSmoo {
    height: 2.6rem;
    width: 2rem;
  }
  p {
    margin: 0;
  }
}
.zamowienie-dust {
  border: 2px solid black;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 1500px) {
    flex-direction: column;
  }
  .ikonki {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    div {
      display: flex;
      height: 4rem;
      justify-content: center;
      align-items: center;
      margin-right: 1.5rem;
    }
    .iko {
      height: 2.6rem;
      width: 2.6rem;
      margin-left: 4px;
    }
    .ikoSmoo {
      height: 2.6rem;
      width: 2rem;
    }
    p {
      margin: 0;
    }
  }
  p {
    margin-bottom: 0rem;
  }
  .nazwa {
    width: 50%;
    @media screen and (max-width: 1500px) {
      width: 100%;
      text-align: center;
    }
  }
  .wartosc-dust {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1500px) {
      width: 100%;
      justify-content: space-around;
    }

    .close-container {
      position: relative;
      /* margin: auto; */
      width: 30px;
      height: 30px;
      /* margin-top: 100px; */
      cursor: pointer;
    }

    .leftright {
      height: 3px;
      width: 20px;
      position: absolute;
      margin-top: 12px;
      background-color: black;
      border-radius: 2px;
      transform: rotate(45deg);
      transition: all 0.3s ease-in;
      opacity: 1;
    }

    .rightleft {
      height: 3px;
      width: 20px;
      position: absolute;
      margin-top: 12px;
      background-color: black;
      border-radius: 2px;
      transform: rotate(-45deg);
      transition: all 0.3s ease-in;
      opacity: 1;
    }

    .close-container:hover .leftright {
      transform: rotate(-45deg);
      background-color: #f25c66;
    }
    .close-container:hover .rightleft {
      transform: rotate(45deg);
      background-color: #f25c66;
    }
    .close-container:hover label {
      opacity: 1;
    }
  }
}
